import { ChallengesDetails } from "app/components/ChallengesDetails";
import LoaderFullCentered from "app/components/Loader/LoaderFullCentered";
import { NotFound } from "app/components/NotFound";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useGetChallengeByIdQuery } from "services/graphql";
import styles from "./ChallengePage.module.scss";

interface ChallengeParams {
  challengeId: string;
}

function ChallengePageInner({ challengeId }: ChallengeParams) {
  const { loading, data: challengeData } = useGetChallengeByIdQuery({
    variables: {
      challengeByIdId: challengeId,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <LoaderFullCentered />;
  }

  if (!challengeData?.challengeById) {
    return <NotFound />;
  }

  return (
    <div className={styles.challengePage}>
      <div className={styles.challengePageCard}>
        <ChallengesDetails challenge={challengeData.challengeById} />
      </div>
    </div>
  );
}

export default function ChallengePage() {
  const { challengeId } = useParams<ChallengeParams>();

  if (!challengeId) {
    return <Redirect to="/challenges" />;
  }

  return <ChallengePageInner challengeId={challengeId} />;
}
